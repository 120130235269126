import { authedFetch, noAuthFetch } from "../utils/fetch";
import { verifyForErrors } from "../utils/handleHttpError";

const apiRoot = process.env.REACT_APP_API_ROOT;

/**
 * @typedef {Object} ReviewAnswer
 * @property {string} choice
 * @property {string} [explanation]
 * @property {boolean} [requireExplanation]
 *
 * @typedef {Object} CreditApp
 * @property {string} businessLegalName
 * @property {string} yearsInBusiness
 * @property {string} businessType
 * @property {boolean} canProvideGuarantee
 * @property {boolean} unsettledObligations
 * @property {boolean} filledBankruptcy
 * @property {ReviewAnswer} reviewPropertyOwnership
 * @property {ReviewAnswer} reviewNoPropertyOwnershipFound
 * @property {ReviewAnswer} reviewUnsettledObligations
 * @property {ReviewAnswer} reviewDebtIncreaseReason
 * @property {ReviewAnswer} reviewRevenueDecreaseReason
 * @property {ReviewAnswer} reviewFilledBankruptcy
 * @property {ReviewAnswer} reviewCanProvideGuarantee
 * @property {string} contactName
 * @property {string} contactEmail
 * @property {string} [contactPhoneNumber]
 * @property {string} countryCode
 * @property {string} state
 * @property {string} streetAddress
 * @property {string} [unitNumber]
 * @property {string} city
 * @property {string} postalCode
 * @property {Array<{filename: string, status: string}>} [financialDocuments]
 * @property {Array<{filename: string, status: string}>} [otherDocuments]
 *
 *
 */

export const getCreditAppById = async creditAppId => {
  return (
    noAuthFetch(`${apiRoot}/payment-proposal/jsonrpc`, {
      body: JSON.stringify({
        method: "getCreditAppById",
        params: { creditAppId }
      })
    })
      .then(res => res.json())
      //@ts-ignore
      .then(body => body?.data)
      .then(verifyForErrors)
  );
};

export const createCreditApp = async financingScenarioId => {
  return (
    authedFetch(`${apiRoot}/payment-proposal/jsonrpc-secured`, {
      body: JSON.stringify({
        method: "createCreditApp",
        params: { financingScenarioId }
      })
    })
      .then(res => res.json())
      //@ts-ignore
      .then(body => body?.data)
      .then(data =>
        verifyForErrors(
          data,
          "Error on creating credit app. Verify if the credit app for this project does not already exist"
        )
      )
  );
};

export const updateCreditApp = async (userEmail, creditApp, options = {}) => {
  const requestProperties = {
    body: JSON.stringify({
      method: "updateCreditApp",
      params: { ...creditApp, userEmail }
    })
  };
  return options?.secured
    ? authedFetch(
        `${apiRoot}/payment-proposal/jsonrpc-secured`,
        requestProperties
      )
    : noAuthFetch(`${apiRoot}/payment-proposal/jsonrpc`, requestProperties)
        .then(res => res.json())
        //@ts-ignore
        .then(body => body?.data)
        .then(data => verifyForErrors(data, "Error on updating credit app"));
};

export const getSignedUploadUrl = async (creditAppId, filename) => {
  return (
    noAuthFetch(`${apiRoot}/funder-operations/unsecure-jsonrpc`, {
      body: JSON.stringify({
        method: "com.enpowered.funderoperations.documentUploadService",
        params: {
          sourceContext: "com.enpowered.funderoperations",
          action: "requestDocumentUpload",
          payload: {
            fundingPackageName: creditAppId,
            fundingDocumentIds: [filename]
          }
        }
      })
    })
      //@ts-ignore
      .then(res => res.json())
      .then(data => verifyForErrors(data, "Error on preparing file upload"))
      .then(result => result?.[0]?.presignedUrl)
  );
};

export const uploadFile = (url, file) =>
  fetch(url, {
    method: "PUT",
    body: file
  }).catch(err => {
    console.error("error on uploading file", err);
    return Promise.reject(err);
  });

export const getDocumentCharacterizations = async (creditAppId, filename) =>
  noAuthFetch(`${apiRoot}/funder-operations/unsecure-jsonrpc`, {
    body: JSON.stringify({
      method: "com.enpowered.funderoperations.documentUploadService",
      params: {
        sourceContext: "com.enpowered.funderoperations",
        action: "getDocumentCharacterizations",
        payload: {
          fundingPackageName: creditAppId,
          fundingDocumentIds: [filename]
        }
      }
    })
  })
    .then(res => res.json())
    //@ts-ignore
    .then(verifyForErrors);

export const deleteUploadedDocument = async (creditAppId, filename) =>
  noAuthFetch(`${apiRoot}/funder-operations/unsecure-jsonrpc`, {
    body: JSON.stringify({
      method: "com.enpowered.funderoperations.documentUploadService",
      params: {
        sourceContext: "com.enpowered.funderoperations",
        action: "deleteDocuments",
        payload: {
          fundingPackageName: creditAppId,
          fundingDocumentIds: [filename]
        }
      }
    })
  })
    .then(res => res.json())
    .then(verifyForErrors);

export const getBusinessType = async business_type =>
  noAuthFetch(`${apiRoot}/funder-operations/external-unsecure-jsonrpc`, {
    body: JSON.stringify({
      method: "com.enpowered.funderoperations.reviewBusiness",
      params: {
        sourceContext: "com.enpowered.funderoperations",
        action: "reviewBusiness",
        payload: { business_type }
      }
    })
  })
    .then(res => res.json())
    .then(verifyForErrors);

var local = false;
export const getReviewQuestions = async (creditAppId, userEmail) =>
  local
    ? Promise.resolve([
        { name: "businessType" },
        {
          name: "reviewCanProvideGuarantee",
          reasons: ["reason 1", "reason 2", "reason 3"]
        },
        { name: "reviewDebtIncreaseReason", reasons: ["reason"] },
        { name: "reviewRevenueDecreaseReason", reasons: ["reason"] },
        { name: "reviewUnsettledObligations" },
        { name: "reviewFilledBankruptcy" },
        { name: "reviewPropertyOwnership", reasons: ["fulano", "ciclano"] },
        { name: "reviewFinancialDocuments" }
      ])
    : noAuthFetch(`${apiRoot}/payment-proposal/jsonrpc`, {
        body: JSON.stringify({
          method: "getReviewQuestions",
          params: { creditAppId, userEmail }
        })
      })
        .then(res => res.json())
        //@ts-ignore
        .then(body => body?.data)
        .then(data =>
          verifyForErrors(
            data,
            "Error on veryifing credit app completeness. Please contact our support for assistance"
          )
        );

export const createSummaryFile = creditAppId =>
  noAuthFetch(`${apiRoot}/funder-operations/external-unsecure-jsonrpc`, {
    body: JSON.stringify({
      method: "com.enpowered.funderoperations.documentExportService",
      params: {
        sourceContext: "com.enpowered.funderoperations",
        action: "exportCharacterizations",
        payload: {
          fundingPackageName: creditAppId,
          exportType: "pdf"
        }
      }
    })
  })
    .then(res => res.json())
    .then(verifyForErrors);

export const sendCreditAppViewedEmail = (financingScenarioId, userEmail) =>
  noAuthFetch(`${apiRoot}/payment-proposal/jsonrpc`, {
    body: JSON.stringify({
      method: "sendCreditAppViewedEmail",
      params: { financingScenarioId, userEmail }
    })
  })
    .then(res => res.json())
    .then(verifyForErrors);
